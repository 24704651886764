import React from 'react';

import Layout from '../../components/layout';
import SEO from '../../components/seo';

import Styles from './thanks.module.css';

const ThanksPage = () => (
  <Layout>
    <SEO title="THANKS FOR YOUR MESSAGE" />
    <section className={Styles.thanks}>
      <div className="container">
        <h1>Thank you for your message</h1>
        <p>メッセージを送信していただき、ありがとうございました。</p>
      </div>
    </section>
  </Layout>
);

export default ThanksPage;
